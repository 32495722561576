import React from 'react'
import { Box, Text, Card } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`],
  },
}

export default () => (
  <>
    <Seo title='Syarat dan Kondisi' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Syarat dan Kondisi' />
        <Divider />
        <Box sx={styles.column}>
          <Card variant='paper'>
            <Text variant='p'>
              Semua konten di dalam blog ini hanyalah untuk menyampaikan tentang
              apa yang ada di dalam pikiran saya.
            </Text>
            <Text variant='p'>
              Apabila saya mengutip dari sumber lain, tentunya saya sertakan
              sumbernya.
            </Text>
            <Text variant='p'>
              Namun saya tidak berani menjamin 100% bahwa apa yang saya tulis
              adalah benar dan valid.
            </Text>
            <Text variant='p'>
              Anda boleh mengutip sebagian atau seluruh cerita saya di blog ini,
              namun jangan mengaku bahwa Anda yang menulis cerita aslinya.
              Jangan memenggal kutipan agar tidak terjadi perbedaan makna.
            </Text>
            <Text variant='p'>
              Saya tidak bertanggung jawab apabila terjadi sesuatu pada diri
              Anda, keluarga Anda, teman Anda, maupun pihak lain yang disebabkan
              oleh memanfaatkan informasi yang ada di dalam blog saya ini.
            </Text>
            <Text variant='p'>
              Kolom komentar terbuka untuk umum. Anda dapat memberikan komentar
              ke dalam artikel. Berkomentarlah dengan bijak. Dilarang
              berkomentar yang dapat menimbulkan keributan, permusuhan, termasuk
              ujaran kebencian.
            </Text>
            <Text variant='p'>
              Syarat dan ketentuan ini dapat berubah sewaktu-waktu tanpa
              pemberitahuan sebelumnya.
            </Text>
          </Card>
        </Box>
      </Main>
    </Stack>
  </>
)
